import { mentorRoutes } from '@/router/navigationRoutes/mentorRoutes';
import { Routes } from '@/router/routes';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { userModule } from '@/store/modules/user/user.module';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { adminRoutes } from './adminRoutes';
import { assignmentRoutes } from './assignmentRoutes';
import { employeeRoutes } from './employeeRoutes';
import { evaluationRoutes } from './evaluationRoutes';
import { internRoutes } from './internRoutes';
import { reportRoutes } from './reportRoutes';

export const navigationRoutes: RouteConfig[] = [
  ...mentorRoutes,
  ...internRoutes,
  ...employeeRoutes,
  ...assignmentRoutes,
  ...evaluationRoutes,
  ...reportRoutes,
  ...adminRoutes,
  {
    path: '',
    name: Routes.Root,
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>): void => {
      switch (userModule.role) {
        case AppRole.Mentor:
          return next({ name: Routes.InternsSelection });
        case AppRole.Intern:
          return next({ name: Routes.AssignmentOverview });
        case AppRole.Employee:
          return next({ name: Routes.UserSelection });
        case AppRole.Admin:
            return next({ name: Routes.RolesManager });
        default:
          //what if no role is present?
          return next({ name: Routes.Unauthorized });
      }
    },
  },
];
