export class LoadableList<T> {
  // tslint:disable-next-line: variable-name
  private _loaded = false;
  // tslint:disable-next-line: variable-name
  private _content: T[] = [];

  constructor(content?: T[]) {
    if (content) {
      this.content = content;
    }
  }

  public get content(): T[] {
    // if (!this._loaded) {
    //    throw new Error(`content not loaded yet.`);
    // }
    return this._content;
  }

  public set content(content: T[]) {
    this._loaded = true;
    this._content = content;
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  /**
   *
   * @param hard If true, content will also be cleared. default:false
   */
  public invalidate(hard = false): void {
    this._loaded = false;
    if (hard) {
      this._content = [];
    }
  }
}
