import { getModule, Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { apiService } from '@/services/api.service';
import { IApiDiscipline } from '@/store/modules/masterdata/contracts/discipline-api.contract';

export interface IMasterdataState {
  disciplines: IApiDiscipline[];
}

@Module({ dynamic: true, store, name: 'masterdata' })
class MasterdataModule extends VuexModule implements IMasterdataState {
  public disciplines: IApiDiscipline[] = [];

  @MutationAction
  public async fetchMasterdata(): Promise<{ disciplines: IApiDiscipline[] }> {
    const disciplines = await apiService.getDisciplines();

    return { disciplines };
  }
}

export const masterdataModule = getModule(MasterdataModule);
