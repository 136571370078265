export enum Routes {
  AssignmentEdit = 'AssignmentEdit',
  AssignmentOverview = 'AssignmentOverview',
  Unauthorized = 'Unauthorized',
  InternsSelection = 'InternsSelection',
  RoleSelection = 'RoleSelection',
  Root = 'Root',
  CreateAssignmentView = 'CreateAssignmentView',
  Evaluations = 'Evaluations',
  CreateEvaluation = 'CreateEvaluation',
  UserSelection = 'UserSelection',
  AssignmentBulkImport = 'AssignmentBulkImport',
  CreateImportMapping = 'CreateImportMapping',
  ImportImportMapping = 'ImportImportMapping',
  EditImportMapping = 'EditImportMapping',
  CreateDisciplinesReport = 'CreateDisciplinesReport',
  CreateEvaluationsReport = 'CreateEvaluationsReport',
  CreateInternReport = 'CreateInternReport',
  RolesManager = 'RolesManager',
}
