import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dayjs from 'dayjs';
// import { CookieService } from '@/services/cookie-service';

Vue.use(VueI18n);

const i18n = new VueI18n({
  fallbackLocale: 'nl',
  locale: 'nl',
});

export const interfaceLanguages = ['nl', 'fr'];

export const onLanguageChangedListeners: Array<(lang: string) => void> = [];
export const onLanguageChangedListenersFirst: Array<(lang: string) => void> = [];

const loadedLanguages: string[] = [];

async function setI18nLanguage(lang: string): Promise<string> {
  i18n.locale = lang;
  dayjs.locale(lang);
  document.documentElement.setAttribute('lang', lang);
  onLanguageChangedListenersFirst.forEach((listener) => listener(lang));
  onLanguageChangedListeners.forEach((listener) => listener(lang));
  return lang;
}

export async function loadLanguage(lang: string): Promise<string> {
  if (!interfaceLanguages.some((l) => l === lang)) {
    lang = 'nl'; // use nl as default if language is unknown
  }
  // CookieService.setLanguage(lang);
  if (loadedLanguages.includes(lang)) {
    return await setI18nLanguage(lang);
  } else {
    await setI18nLanguage(lang);

    let msgs: {
      default: VueI18n.LocaleMessageObject;
    };
    switch (lang) {
      case 'fr':
        msgs = await import(/* webpackChunkName: "lang-fr" */ `@/plugins/i18n/locales/fr.json`);
        await import(/* webpackChunkName: "lang-fr" */ `@/../node_modules/dayjs/locale/fr.js` as any);
        dayjs.locale('fr');
        break;
      case 'nl':
      default:
        msgs = await import(/* webpackChunkName: "lang-nl" */ `@/plugins/i18n/locales/nl.json`);
        await import(/* webpackChunkName: "lang-nl" */ `@/../node_modules/dayjs/locale/nl-be.js` as any);
        dayjs.locale('nl-be');
        break;
    }

    i18n.mergeLocaleMessage(lang, msgs.default);
    loadedLanguages.push(lang);
    return lang;
  }
}

export default i18n;
