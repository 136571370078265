import Constants from '../constants';

export class UrlService {
  public static getPrivacyStatementUrl(locale: string): string {
    return locale === 'fr' ? Constants.privacyStatementFr : Constants.privacyStatementNl;
  }

  public static getCookieStatementUrl(locale: string): string {
    return locale === 'fr' ? Constants.cookieStatementFr : Constants.cookieStatementNl;
  }
}
