import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { IApiEvaluation } from '@/store/modules/evaluations/contracts/evaluation.api-contract';
import { apiService } from '@/services/api.service';
import { userModule } from '@/store/modules/user/user.module';
import { internModule } from '@/store/modules/intern/intern.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { LoadableList } from '@/models/LoadableList';

export interface IEvaluationState {
  evaluation: IApiEvaluation | null;
  evaluations: LoadableList<IApiEvaluation>;
  allLoaded: boolean;
}

@Module({ dynamic: true, store, name: 'evaluation' })
class EvaluationModule extends VuexModule implements IEvaluationState {
  evaluation: IApiEvaluation | null = null;
  evaluations: LoadableList<IApiEvaluation> = new LoadableList<IApiEvaluation>();
  public allLoaded = false;

  @Action({})
  public resetEvaluationModule() {
    this.RESET_EVALUATIONS();
    this.SET_EVALUATION(null);
    this.SET_ALL_EVALUATIONS_LOADED(false);
  }

  @Action({})
  public async fetchMoreEvaluations(): Promise<void> {
    const size = 20;
    const skip = this.evaluations.content.length;
    const newEvaluations = await this.loadNewEvaluations({ skip, size });
    this.SET_EVALUATIONS(await this.filterDoubleEvaluations([...this.evaluations.content, ...newEvaluations]));
    if (newEvaluations.length < size) {
      this.SET_ALL_EVALUATIONS_LOADED(true);
    }
  }

  //don't like why this should be an action
  @Action({})
  private async filterDoubleEvaluations(evaluations: IApiEvaluation[]): Promise<IApiEvaluation[]> {
    return evaluations.filter((e, index, array) => array.findIndex((ev) => e.id === ev.id) === index);
  }

  //If there are new evaluations made while user is in application, they won't show until refresh of page.
  @Action({})
  public async fetchEvaluations(): Promise<void> {
    if (this.evaluations.loaded) {
      return;
    }
    const size = 20;
    const newEvaluations = await this.loadNewEvaluations({ skip: 0, size });
    this.SET_EVALUATIONS(newEvaluations);
    if (newEvaluations.length < size) {
      this.SET_ALL_EVALUATIONS_LOADED(true);
    }
  }

  @Action({})
  private async loadNewEvaluations(queryParams: { skip: number; size: number }): Promise<IApiEvaluation[]> {
    const internIds: string[] | undefined =
      userModule.role === AppRole.Intern
        ? undefined
        : internModule.selectedInterns.map((i) => {
            return i.id;
          });
    return (
      await apiService.getEvaluations({
        internIds,
        take: queryParams.size,
        skip: queryParams.skip,
      })
    ).result;
  }

  @Action({})
  public async fetchEvaluation(evaluationId: string): Promise<void> {
    let evaluation = this.evaluations.content.find((e) => e.id === evaluationId);
    if (!evaluation) {
      evaluation = await apiService.getEvaluation(evaluationId);
    }
    this.SET_EVALUATION(evaluation);
  }

  @Action({})
  public setEvaluation(evaluation: IApiEvaluation): void {
    this.SET_EVALUATION(evaluation);
  }

  @Action({})
  public addEvaluation(evaluation: IApiEvaluation): void {
    this.SET_EVALUATIONS([evaluation, ...this.evaluations.content]);
    this.SET_EVALUATION(evaluation);
  }

  @Mutation
  public SET_EVALUATION(evaluation: IApiEvaluation | null): void {
    this.evaluation = evaluation;
  }

  @Mutation
  public SET_EVALUATIONS(evaluations: IApiEvaluation[]) {
    this.evaluations.content = evaluations;
  }

  @Mutation
  public RESET_EVALUATIONS(): void {
    this.evaluations = new LoadableList<IApiEvaluation>();
  }

  @Mutation
  public SET_ALL_EVALUATIONS_LOADED(loaded: boolean): void {
    this.allLoaded = loaded;
  }
}

export const evaluationModule = getModule(EvaluationModule);
