import { IDisciplinesReportFilterModel } from '@/components/reports/DisciplinesReportFilter.vue';
import { IInternReportFilterModel } from '@/components/reports/InternReportFilter.vue';
import { IAssignmentsQuery } from '@/contracts/assignment.contract';
import { CrmContact, CrmOrganization, IGetQuery, Pagination } from '@/contracts/crm-contract';
import { IPagedResult } from '@/contracts/paging.contract';
import { IApiUserRole } from "@/contracts/user-role.contract";
import { notificationService } from '@/services/notification.service';
import store from '@/store';
import {
  IApiAssignmentComment,
  IApiAssignmentCommentData,
} from '@/store/modules/assignments/contracts/assignment-comment.api-contract';
import {
  IApiAssignment,
  IApiAssignmentData,
  IApiAssignmentsPagedList,
} from '@/store/modules/assignments/contracts/assignment.api-contract';
import {
  IApiEvaluation,
  IApiEvaluationData,
  IApiEvaluationQuery,
} from '@/store/modules/evaluations/contracts/evaluation.api-contract';
import {
  IApiImportMappingDataModel,
  ImportMappingReadModel,
} from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';
import { IApiBaseInternship, IApiInternship } from '@/store/modules/internship/contracts/internship.api-contract';
import { IApiDiscipline } from '@/store/modules/masterdata/contracts/discipline-api.contract';
import { IPerson, IPersonQuery } from '@/store/modules/user/contracts/person.contract';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { userModule } from '@/store/modules/user/user.module';
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import i18n from '../plugins/i18n/i18n';

class ApiService {
  private apiClient: AxiosInstance;

  constructor() {
    this.apiClient = axios.create({
      baseURL: 'api',
    });

    this.apiClient.interceptors.request.use((config) => {
      config.paramsSerializer = (p) => qs.stringify(p, { arrayFormat: 'repeat' });

      if (store.getters['oidc/oidcIsAuthenticated']) {
        config.headers = {
          ...(config.headers || {}),
          authorization: `bearer ${store.state.oidc.access_token}`,
        };
      }
      if ((userModule.originalUser?.role === AppRole.Employee || userModule.originalUser?.role === AppRole.Admin) && userModule.person) {
        config.headers = {
          ...(config.headers || {}),
          'sdb-imp-id': userModule.person.id,
        };
      }
      config.headers = {
        ...(config.headers || {}),
        'accept-language': i18n.locale,
      };
      return config;
    });

    this.apiClient.interceptors.response.use((response) => {
      if (response.status === 401) {
        notificationService.onError(i18n.t('errors.401').toString());
      }
      return response;
    });
  }

  public get client(): AxiosInstance {
    return this.apiClient;
  }

  public async getSelf(): Promise<IPerson> {
    return (await this.client.get('users/self')).data;
  }

  public async getRoles(id: string): Promise<AppRole[]> {
    return (await this.client.get(`users/${id}/roles`)).data;
  }

  public async getInterns(id: string): Promise<IPerson[]> {
    return (await this.client.get(`users/${id}/interns`)).data;
  }

  public async getDisciplines(): Promise<IApiDiscipline[]> {
    return (await this.client.get('assignments/disciplines')).data;
  }

  public async createAssignment(assignmentData: IApiAssignmentData): Promise<IApiAssignment> {
    return (await this.client.post('assignments', { assignmentsData: [assignmentData] })).data[0];
  }

  public async createAssignments(assignmentDataArray: IApiAssignmentData[]): Promise<IApiAssignment[]> {
    return (await this.client.post('assignments', { assignmentsData: assignmentDataArray })).data;
  }

  public async getAssignments(query: IAssignmentsQuery): Promise<IApiAssignmentsPagedList> {
    return (
      await this.client.get('assignments', {
        params: query,
      })
    ).data;
  }

  public async getAssignment(assignmentId: string): Promise<IApiAssignment> {
    return (await this.client.get(`assignments/${assignmentId}`)).data;
  }

  public async editAssignment(assignmentId: string, assignmentData: IApiAssignmentData): Promise<IApiAssignment> {
    return await (
      await this.client.put(`assignments/${assignmentId}`, assignmentData)
    ).data;
  }

  public async getInternship(id: string): Promise<IApiBaseInternship> {
    return (await this.client.get(`/users/${id}/internships`)).data;
  }

  public async getEvaluations(query: IApiEvaluationQuery): Promise<IPagedResult<IApiEvaluation>> {
    //if user has role Mentor, this will return the evaluations of the selected interns
    //if user has role Intern, this will return the user's evaluations
    return (await this.client.get('/evaluations', { params: query })).data;
  }

  public async getEvaluation(evaluationId: string): Promise<IApiEvaluation> {
    return (await this.client.get(`/evaluations/${evaluationId}`)).data;
  }

  public async createEvaluation(evaluationData: IApiEvaluationData): Promise<IApiEvaluation> {
    return (await this.client.post('/evaluations', evaluationData)).data;
  }

  public async getUsers(query: IPersonQuery): Promise<IPagedResult<IPerson>> {
    return (await this.client.get('/users', { params: query })).data;
  }

  public async createComment(commentData: IApiAssignmentCommentData): Promise<IApiAssignmentComment> {
    return (await this.client.post('/assignments/comments', commentData)).data;
  }

  public async createImportMapping(
    id: string,
    importMappingData: IApiImportMappingDataModel,
  ): Promise<ImportMappingReadModel> {
    return (await this.client.put(`/users/${id}/import-mapping`, importMappingData)).data;
  }

  public async getImportMapping(id: string): Promise<ImportMappingReadModel> {
    return (await this.client.get(`/users/${id}/import-mapping`)).data;
  }

  public async deleteAssignment(assignmentId: string): Promise<void> {
    await this.client.delete(`assignments/${assignmentId}`);
  }

  public async getDisciplinesReport(filterModel: IDisciplinesReportFilterModel): Promise<BlobPart> {
    return (await this.client.get('reports/disciplines', { params: filterModel, responseType: 'blob' })).data;
  }

  public async getEvaluationsReport(filterModel: { startDate: string }): Promise<BlobPart> {
    return (await this.client.get('reports/evaluations', { params: filterModel, responseType: 'blob' })).data;
  }

  public async getInternships(): Promise<IApiInternship[]> {
    return (await this.client.get('users/internships')).data;
  }

  public async getInternReport(filterModel: IInternReportFilterModel): Promise<BlobPart> {
    return (await this.client.get('reports/interns', { params: filterModel, responseType: 'blob' })).data;
  }

  public async getRolesByUsersId(id: string): Promise<IApiUserRole[]> {
    return (await this.client.get(`roles?personId=${id}`)).data;
  }

  public async deleteRole(id: number): Promise<void> {
    return (await this.client.delete(`roles/${id}`)).data;
  }

  public async addRoleToUser(userId: string, role: string): Promise<IApiUserRole> {
    return (await this.client.post(`roles`, {
      personId: userId,
      role: role
    })).data;
  }

  public async addContactRole(crmId: string, role: string): Promise<IApiUserRole> {
    return (await this.client.post(`roles`, {
      crmId: crmId,
      role: role
    })).data;
  }

  public async hasInternships(crmId: string): Promise<boolean> {
    return (await this.client.get(`roles/hasInternships/${crmId}`)).data;
  }

  public async hasMentorInternships(crmId: string): Promise<boolean> {
    return (await this.client.get(`roles/hasMentorInternships/${crmId}`)).data;
  }

  public async searchContacts(getContactsQuery: IGetQuery): Promise<Pagination<CrmContact>> {
    return (
        await this.client.get('crm/contacts', {
          params: {
            ...getContactsQuery,
          },
        })
    ).data;
  }

  public async searchOrganizations(getOrganizationsQuery: IGetQuery): Promise<Pagination<CrmOrganization>> {
    return (
        await this.client.get('crm/organizations', {
          params: {
            ...getOrganizationsQuery,
          },
        })
    ).data;
  }

}

export const apiService = new ApiService();
