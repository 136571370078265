



































































import { CrmContact } from "@/contracts/crm-contract";
import { apiService } from "@/services/api.service";
import { notificationService } from "@/services/notification.service";
import { AppRole } from "@/store/modules/user/contracts/role.enum";
import { getRoleColor } from "@/views/admin/role-colors";
import { Component, Model, Vue, Watch } from "vue-property-decorator";

@Component({
  methods: {
    Role() {
      return AppRole
    }, getRoleColor
  },
})
export default class AddContactDialog extends Vue {
  @Model('input')
  public readonly isOpen!: boolean;
  public contactLoading = false;
  public loading = false;
  public contacts: CrmContact[] = [];
  public timeout: any = null;

  public contactId: string | null = null;
  public selectedRole: AppRole | null = null;
  public hasInternships: boolean | null = null;
  public hasMentorInternships: boolean | null = null;

  _contactSearch = '';

  set contactSearch(val: string) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._contactSearch = val;
      if (!this.contactId)
        this.onSearchChanged(val);
    }, 300);
  }

  get contactSearch() {
    return this._contactSearch;
  }

  async onSearchChanged(value: string) {
    if (value && value.length > 2) {
      this.contactLoading = true;
      this.contacts = (await apiService.searchContacts({name: value})).data;
      this.contactLoading = false;
    }
  }

  get roleSelections() {
    return (Object.keys(AppRole) as Array<keyof typeof AppRole>).map((role) => {
      return role;
    })
  }

  async addContact() {
    if (this.contactId && this.selectedRole) {
      this.loading = true;
      const contact = this.contacts.find((x) => x.id == this.contactId);
      apiService.addContactRole(this.contactId, this.selectedRole)
      .then((role) => {
        if(role) {
          this.$emit('close-dialog', {id: role.personId, firstName: contact?.firstName, lastName: contact?.lastName});
        } else {
          notificationService.onError(this.$t('roleManager.addContact.noValidInternships').toString());
          this.loading = false;
        }
      })
      .catch((x) => {
        if(x.response.status == 400) {
          notificationService.onError(this.$t('roleManager.addContact.noValidMentor').toString());
        }
        this.loading = false;
      });
    }
  }
  @Watch('contactId')
  async onContactIdChanged() {
    if(this.contactId) {
      this.hasInternships = await apiService.hasInternships(this.contactId);
      this.hasMentorInternships = await apiService.hasMentorInternships(this.contactId);
    }
  }

  public reset(): void {
    this.contactId = null;
    this.selectedRole = null;
    this.hasInternships = null;
    this.hasMentorInternships = null;
    this.loading = false;
    this.contactLoading = false;
    this.contacts = [];
  }

  @Watch('isOpen')
  onIsOpenChanged() {
    if (!this.isOpen) {
      this.reset();
    }
  }

  get disabled() {
    return !this.contactId || !this.selectedRole || (this.selectedRole == AppRole.Mentor && this.hasMentorInternships == false) || (this.selectedRole == AppRole.Intern && this.hasInternships == false)
  }
}
