import { LoadableList } from '@/models/LoadableList';
import i18n, { loadLanguage } from '@/plugins/i18n/i18n';
import { apiService } from '@/services/api.service';
import store from '@/store';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';
import { internModule } from '@/store/modules/intern/intern.module';
import { internshipModule } from '@/store/modules/internship/internship.module';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';
import { IPerson } from '@/store/modules/user/contracts/person.contract';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';

export interface IUser {
  person: IPerson;
  role: AppRole;
}

const impersonatedUser = 'impersonatedUser';

export interface IUserState {
  person: IPerson | null;
  role: AppRole | null;
  roles: LoadableList<AppRole>;
}

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class UserModule extends VuexModule implements IUserState {
  person: IPerson | null = null;
  role: AppRole | null = null;
  roles: LoadableList<AppRole> = new LoadableList<AppRole>();
  originalUser: IUser | null = null;

  @MutationAction({ mutate: ['person'] })
  public async fetchSelf() {
    const person = await apiService.getSelf();
    await loadLanguage(person.language);
    return { person };
  }

  @Action({})
  public async fetchRoles(userId: string) {
    const roles = await apiService.getRoles(userId);
    this.SET_ROLES(roles);
    if (roles.length === 1) {
      this.SET_ROLE(roles[0]);
    } else if(roles.length > 0) {
      if(roles.includes(AppRole.Admin)) {
        this.SET_ROLE(AppRole.Admin);
      }
    }
    if (this.role === AppRole.Employee || this.role === AppRole.Admin) {
      const user =
        (sessionStorage.getItem(impersonatedUser) &&
          (JSON.parse(sessionStorage.getItem(impersonatedUser) as string) as IUser)) ||
        null;
      if (user) {
        await this.impersonate(user);
      }
    }
  }

  @Action({})
  public async impersonate(user: IUser): Promise<void> {
    if (!this.person || !this.role) {
      return;
    }
    this.SET_ORIGINAL_USER({
      person: this.person,
      role: this.role,
    });
    this.SET_PERSON(user.person);
    this.SET_ROLE(user.role);
    if (user.person.language !== i18n.locale) {
      await loadLanguage(user.person.language);
      await masterdataModule.fetchMasterdata();
    }
    sessionStorage.setItem(impersonatedUser, JSON.stringify(user));
  }

  @Action({})
  public async stopImpersonation(): Promise<void> {
    if (!this.originalUser) {
      return;
    }
    //reset user
    this.SET_PERSON(this.originalUser.person);
    this.SET_ROLE(this.originalUser.role);
    this.SET_ORIGINAL_USER(null);

    //reset internship
    await internshipModule.resetInternshipModule();

    //reset interns
    await internModule.resetInternModule();

    //reset assignments
    await assignmentModule.resetAssignmentModule();

    //reset evaluations
    await evaluationModule.resetEvaluationModule();

    //clear session store of impersonated user
    sessionStorage.clear();

    //reset language to that of impersonator
    if (this.person && this.person.language !== i18n.locale) {
      await loadLanguage(this.person.language);
      await masterdataModule.fetchMasterdata();
    }
  }

  @Mutation
  public SET_PERSON(person: IPerson) {
    this.person = person;
  }

  @Mutation
  public SET_ROLES(roles: AppRole[]) {
    this.roles.content = roles;
  }

  @Mutation
  public SET_ROLE(role: AppRole) {
    this.role = role;
  }

  @Mutation
  public SET_ORIGINAL_USER(user: IUser | null) {
    this.originalUser = user;
  }
}

export const userModule = getModule(UserModule);
