





































import FilteredItemsPage from '@/components/general/FilteredItemsPage.vue';
import AddContactDialog from '@/components/roles/AddContactDialog.vue';
import PersonRolesTable from '@/components/roles/PersonRolesTable.vue';
import PersonsFilter from '@/components/roles/PersonsFilter.vue';
import { IPagedResult } from "@/contracts/paging.contract";
import { IApiUserRole } from '@/contracts/user-role.contract';
import { apiService } from "@/services/api.service";
import { IPerson } from "@/store/modules/user/contracts/person.contract";
import { Component, Vue } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';

  type QueryParameters = Partial<{ page: number; pageSize: number;  itaaNrOrName: string}>;
  
  @Component({
    components: {
      FilteredItemsPage,
      PersonRolesTable,
      PersonsFilter,
      AddContactDialog
    },
  })
  export default class RolesManager extends Vue {
    public loading = false;
    public data: IPagedResult<IPerson> = {} as IPagedResult<IPerson>;
    public tablePrefix = 'roleManager';
    public isAddContactDialogOpen = false;


    public async loadPersons(queryParams: QueryParameters): Promise<void> {
        this.loading = true;
        this.data = await apiService.getUsers({
            q: queryParams.itaaNrOrName || '',
            skip: ((queryParams.page || 1) - 1) * (queryParams.pageSize || 20),
            take: queryParams.pageSize || 20,
        });
        this.loading = false;
    }

    public baseFilterModel(empty = false): Record<string, never> {
      return {};
    }


    public queryParser(query: Dictionary<string | (string | null)[]>): Record<string, never> {
      return {};
    }

    public closeAddContact(person?: IPerson) {
      this.isAddContactDialogOpen = false;

      if(person) {
        //open dialog in PersonRolesTable
        if (this.$refs.table) {
          (this.$refs.table as any).editRoles(person);
        }

        this.loadPersons({
          page: this.$route.query.page ? parseInt(this.$route.query.page as string) : 1,
          pageSize: this.$route.query.pageSize ? parseInt(this.$route.query.pageSize as string) : 20,
          itaaNrOrName: this.$route.query.itaaNrOrName ? this.$route.query.itaaNrOrName as string : ''
        });
      }
    }

    public updateRoles(roles?: IApiUserRole[]) {
      if(roles) {
        const personId = roles[0].personId;
        const row = this.data.result.find(x => x.id === personId);
        if(row) {
          row.roles = roles.filter(x => !x.deleted).map(x => x.appRole);
        }
      }
    }
}
  