
































import {TextInput} from '@itaa/ui-components';
import {Component, Vue, VModel} from 'vue-property-decorator';


export interface IPersonsFilterModel {
  itaaNrOrName?: string;
}

@Component({
  components: {
    TextInput,
  },
})
export default class PersonsFilter extends Vue {
  @VModel()
  public filterModel!: IPersonsFilterModel;

  public storedFilterJSON = '';

  public onFilterClear(): void {
    this.filterModel = {};
    this.storeAndEmitFilter();
  }

  public storeAndEmitFilter() {
    this.$emit('filter');
    this.storedFilterJSON = JSON.stringify(this.filterModel);
  }
}
