import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { apiService } from '@/services/api.service';
import { IApiBaseInternship } from '@/store/modules/internship/contracts/internship.api-contract';

export interface IInternshipState {
  startDate: string | null;
  endDate?: string | null;
}

@Module({ dynamic: true, store, name: 'internship' })
class InternshipModule extends VuexModule implements IInternshipState {
  startDate: string | null = null;
  endDate: string | null = null;

  @MutationAction
  public async fetchInternship(id: string): Promise<IApiBaseInternship> {
    return await apiService.getInternship(id);
  }

  @Action({})
  public resetInternshipModule(): void {
    this.SET_START_DATE(null);
    this.SET_END_DATE(null);
  }

  @Mutation
  public SET_START_DATE(date: string | null) {
    this.startDate = date;
  }

  @Mutation
  public SET_END_DATE(date: string | null) {
    this.endDate = date;
  }
}

export const internshipModule = getModule(InternshipModule);
