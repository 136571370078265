import { RouteConfig } from 'vue-router';
import { Routes } from '@/router/routes';
import { roleGuard } from '@/router/guards/role.guards';
import { AppRole } from '@/store/modules/user/contracts/role.enum';

export const mentorRoutes: RouteConfig[] = [
  {
    name: Routes.InternsSelection,
    path: 'interns',
    component: () => import(/* webpackChunkName: "group-mentor" */ '@/views/mentor/InternSelectionView.vue'),
    beforeEnter: roleGuard(AppRole.Mentor),
  },
];
