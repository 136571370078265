import { RouteConfig } from 'vue-router';
import Multiguard from 'vue-router-multiguard';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { internSelectedGuard } from '../guards/intern-selected.guard';
import { roleGuard } from '../guards/role.guards';
import { Routes } from '../routes';
import { importMappingGuard, internshipLoadedGuard } from '@/router/guards/data.guards';

export const assignmentRoutes: RouteConfig[] = [
  {
    name: Routes.AssignmentOverview,
    path: 'assignments',
    component: () => import(/* webpackChunkName: "group-assignment" */ '@/views/assignment/AssignmentOverview.vue'),
    beforeEnter: Multiguard([roleGuard(AppRole.Intern, AppRole.Mentor), internSelectedGuard, internshipLoadedGuard]),
  },
  {
    name: Routes.CreateAssignmentView,
    path: 'assignments/new',
    component: () => import(/* webpackChunkName: "group-assignment" */ '@/views/assignment/CreateAssignmentView.vue'),
    beforeEnter: roleGuard(AppRole.Intern),
  },
  {
    name: Routes.AssignmentEdit,
    path: 'assignments/:id/edit',
    component: () => import(/* webpackChunkName: "group-assignment" */ '@/views/assignment/EditAssignmentView.vue'),
    beforeEnter: roleGuard(AppRole.Intern),
    props: (route) => ({
      assignmentId: route.params.id,
    }),
  },
  {
    name: Routes.AssignmentBulkImport,
    path: 'assignments/bulk-import',
    component: () =>
      import(/* webpackChunkName: "group-assignment-bulk-import" */ '@/views/assignment/AssignmentBulkImportView.vue'),
    beforeEnter: Multiguard([roleGuard(AppRole.Intern), importMappingGuard]),
  },
  {
    name: Routes.CreateImportMapping,
    path: 'assignments/bulk-import/create-mapping',
    component: () =>
      import(/* webpackChunkName: "group-assignment-bulk-import" */ '@/views/assignment/ImportMappingView.vue'),
    beforeEnter: roleGuard(AppRole.Intern),
  },
  {
    name: Routes.ImportImportMapping,
    path: 'assignments/bulk-import/import-mapping',
    component: () =>
      import(/* webpackChunkName: "group-assignment-bulk-import" */ '@/views/assignment/ImportMappingView.vue'),
    beforeEnter: Multiguard([roleGuard(AppRole.Intern), importMappingGuard]),
  },
  {
    name: Routes.EditImportMapping,
    path: 'assignments/bulk-import/edit-mapping',
    component: () =>
      import(/* webpackChunkName: "group-assignment-bulk-import" */ '@/views/assignment/ImportMappingView.vue'),
    beforeEnter: Multiguard([roleGuard(AppRole.Intern), importMappingGuard]),
  },
];
