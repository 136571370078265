import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filtered-items-page',{attrs:{"title":_vm.$t('roleManager.title'),"query-parser":_vm.queryParser,"base-filter-model":_vm.baseFilterModel,"table-prefix":_vm.tablePrefix},on:{"load-items":_vm.loadPersons},scopedSlots:_vm._u([{key:"actionBtn",fn:function(){return [_c(VBtn,{staticClass:"mb-3",attrs:{"color":"primary","x-large":""},on:{"click":function($event){_vm.isAddContactDialogOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('roleManager.addContact.button'))+" ")])]},proxy:true},{key:"filter",fn:function(ref){
var onFilter = ref.onFilter;
var clearFilter = ref.clearFilter;
var filterModel = ref.filterModel;
var updateFilterModel = ref.updateFilterModel;
return [_c('persons-filter',{attrs:{"value":filterModel},on:{"filter":onFilter,"clear":clearFilter,"input":updateFilterModel}})]}},{key:"table",fn:function(ref){
var page = ref.page;
var pageSize = ref.pageSize;
var loadNewPage = ref.loadNewPage;
var tableInitialized = ref.tableInitialized;
return [(tableInitialized)?_c('person-roles-table',{ref:"table",attrs:{"items":_vm.data,"page":page,"page-size":pageSize,"loading-data":_vm.loading},on:{"page-change":loadNewPage,"roles-updated":_vm.updateRoles}}):_vm._e()]}}])}),_c('add-contact-dialog',{on:{"close-dialog":_vm.closeAddContact},model:{value:(_vm.isAddContactDialogOpen),callback:function ($$v) {_vm.isAddContactDialogOpen=$$v},expression:"isAddContactDialogOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }