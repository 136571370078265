import { getModule, Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { apiService } from '@/services/api.service';
import {
  IApiImportMappingDataModel,
  ImportMappingReadModel,
} from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';

export interface IImportMappingState {
  importMapping: ImportMappingReadModel | null;
}

@Module({ dynamic: true, store, name: 'import-mapping' })
class ImportMappingModule extends VuexModule implements IImportMappingState {
  public importMapping: ImportMappingReadModel | null = null;

  @MutationAction
  public async fetchImportMapping(id: string): Promise<{ importMapping: ImportMappingReadModel }> {
    const importMapping = await apiService.getImportMapping(id);
    return { importMapping };
  }

  @MutationAction
  public async setImportMapping(
    importMapping: ImportMappingReadModel,
  ): Promise<{ importMapping: ImportMappingReadModel }> {
    return { importMapping };
  }

  public get importMappingAsDataModel(): IApiImportMappingDataModel {
    const mapping: IApiImportMappingDataModel = {
      columnMapping: {},
      activityMapping: {},
    } as IApiImportMappingDataModel;
    if (this.importMapping) {
      mapping.columnMapping = this.importMapping.columnMapping;
      for (const activityMapping of this.importMapping.activityMapping) {
        mapping.activityMapping[activityMapping.activity.id] = activityMapping.mapping;
      }
    }
    return mapping;
  }
}

export const importMappingModule = getModule(ImportMappingModule);
