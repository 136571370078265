





































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { IPerson } from '@/store/modules/user/contracts/person.contract';
import { LocaleMessages } from 'vue-i18n';

@Component({})
export default class UserSelector extends Vue {
  @VModel()
  public users!: IPerson[];

  @Prop()
  public label!: string | LocaleMessages ;

  @Prop()
  public subHeader!: string | LocaleMessages ;

  public selectedUsers: number[] = [1];

  @Emit('go-to-selection-view')
  public goToSelectionView(): void {
    return;
  }

}
