





















import ImpersonationSelector from '@/components/nav-side-bar/ImpersonationSelector.vue';
import InternSelector from '@/components/nav-side-bar/InternSelector.vue';
import PvfcButton from '@/components/nav-side-bar/PvfcButton.vue';
import { Routes } from '@/router/routes';
import { internModule } from '@/store/modules/intern/intern.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { userModule } from '@/store/modules/user/user.module';
import { INavItem, IPrivacyAndCookieStatements } from '@itaa/ui-components/dist/components/navigation-components';
import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { UrlService } from '../services/url-service';

@Component({
  components: {
    ImpersonationSelector,
    InternSelector,
    PvfcButton,
  },
  methods: {
    ...mapActions({
      signOutOidc: 'oidc/signOutOidc',
    }),
  },
})
export default class NavigationRoot extends Vue {
  private signOutOidc!: (payload?: any) => void;

  public logOut(): void {
    this.signOutOidc();
  }

  public get showInterns(): boolean {
    return userModule.role === AppRole.Mentor && internModule.selectedInterns.length > 0;
  }

  public get showImpersonation(): boolean {
    //either the employee is impersonating or the employee is on the report pages
    return (userModule.originalUser?.role === AppRole.Employee || userModule.originalUser?.role === AppRole.Admin) || this.isLoggedInAsEmployee;
  }

  public get isLoggedInAsEmployee(): boolean {
    return (this.$route.name !== Routes.UserSelection && this.$route.name !== Routes.RolesManager) && (userModule.role === AppRole.Employee || userModule.role === AppRole.Admin) ;
  }

  public get showPvButton(): boolean {
    return userModule.role === AppRole.Intern;
  }

  public get roleTranslation(): string {
    return this.$t(`roles.${userModule.role}`).toString();
  }

  public get role(): AppRole | null {
    return userModule.role;
  }

  public get itaaLogo(): Record<string, unknown> {
    return require('@/assets/img/logo.png');
  }

  public get navItems(): INavItem<AppRole>[] {
    if (userModule.role === AppRole.Mentor && internModule.selectedInterns.length === 0) {
      return [];
    }
    if (this.isLoggedInAsEmployee) {
      return [
        {
          route: Routes.CreateDisciplinesReport,
          icon: 'disciplines',
          title: this.$t('discipline.disciplines').toString(),
          visibleToRoles: [AppRole.Employee, AppRole.Admin]
        },
        {
          route: Routes.CreateEvaluationsReport,
          icon: 'message-question',
          title: this.$t('report.evaluations.title').toString(),
          visibleToRoles: [AppRole.Employee, AppRole.Admin]
        },
        {
          route: Routes.CreateInternReport,
          icon: 'line-chart',
          title: this.$t('report.interns.internReport').toString(),
          visibleToRoles: [AppRole.Employee, AppRole.Admin]
        }
      ]
    }
    return [
      {
        route: this.Routes.AssignmentOverview,
        icon: 'published',
        title: this.$t('assignment.assignments').toString(),
        visibleToRoles: [AppRole.Intern, AppRole.Mentor],
      },
      {
        route: this.Routes.Evaluations,
        icon: 'evaluation',
        title: this.$t('evaluation.evaluations').toString(),
        visibleToRoles: [AppRole.Intern, AppRole.Mentor],
      },
      {
        route: this.Routes.UserSelection,
        icon: 'person',
        title: this.$t('userSelection.title').toString(),
        visibleToRoles: [AppRole.Admin],
      },
      {
        route: this.Routes.RolesManager,
        icon: 'people',
        title: this.$t('roleManager.title').toString(),
        visibleToRoles: [AppRole.Admin],
      }
    ];
  }

  public get privacyAndCookieStatements(): IPrivacyAndCookieStatements[] {
    return [
      {
        route: UrlService.getPrivacyStatementUrl(this.$i18n.locale),
        title: this.$t('navigationRoot.privacyStatement').toString(),
      },
      {
        route: UrlService.getPrivacyStatementUrl(this.$i18n.locale),
        title: this.$t('navigationRoot.cookieStatement').toString(),
      },
    ];
  }
}
