import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{attrs:{"outlined":"","rounded":""}},[_c('roles-dialog',{attrs:{"person":_vm.selectedPerson,"roles":_vm.roles},on:{"open-dialog":function($event){_vm.showDialog = true;},"close-dialog":_vm.onCloseDialog},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}),_c(VDialog,{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loadingRoles),callback:function ($$v) {_vm.loadingRoles=$$v},expression:"loadingRoles"}},[_c(VCard,{attrs:{"color":"primary","dark":""}},[_c(VCardText,[_vm._v(" Loading "),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c(VDataTable,{staticClass:"activity-history__table data-table-pointer",attrs:{"headers":_vm.headers,"items":_vm.items.result,"disable-sort":true,"page":_vm.page,"items-per-page":_vm.pageSize,"server-items-length":_vm.totalItems,"loading":_vm.loadingData,"loading-text":_vm.$t('roleManager.table.isLoading'),"header-props":{
                  'sort-by-text':_vm.$t('roleManager.table.sortBy')
                },"footer-props":{
                  'items-per-page-text' : _vm.$t('roleManager.table.itemsPerPageText'),
                  'items-per-page-all-text' : _vm.$t('roleManager.table.showAllPages'),
                  'items-per-page-options': [10,20,30,50]
                }},on:{"update:page":function ($event) { return _vm.onPaginationChanged($event, _vm.pageSize); },"update:items-per-page":function ($event) { return _vm.onPaginationChanged(_vm.page, $event); }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
                var pageStart = ref.pageStart;
                var pageStop = ref.pageStop;
                var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(_vm.$t('roleManager.table.pageFromToActivities', { start: pageStart, end: pageStop, total: itemsLength }))+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-h3 py-10"},[_vm._v(" "+_vm._s(_vm.$t('roleManager.table.noData'))+" ")])]},proxy:true},{key:"item.itaaNr",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.itaaNr)+" ")]}},{key:"item.fullName",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.email",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailAddress)+" ")]}},{key:"item.roles",fn:function(ref){
                var item = ref.item;
return [(item.roles.length > 0)?_c('div',_vm._l((item.roles),function(role){return _c(VChip,{key:role,staticClass:"ma-1",attrs:{"color":_vm.getRoleColor(role),"small":""}},[_vm._v(" "+_vm._s(role)+" ")])}),1):_c('span',[_vm._v(_vm._s(_vm.$t('roleManager.details.noData')))])]}},{key:"item.internSince",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.internSince))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.editRoles(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }