import { NavigationGuard } from 'vue-router';
import store from '@/store';

function isAuthenticated(): boolean {
  return store.getters['oidc/oidcIsAuthenticated'];
}

export const authGuard: NavigationGuard = async (to, from, next) => {
  if (!isAuthenticated()) {
    await store.dispatch('oidc/oidcCheckAccess', to);
    if (!isAuthenticated()) {
      store.dispatch('oidc/authenticateOidc', {
        redirectPath: to.fullPath,
      });
      return false;
    }
    next();
  }
  next();
};
