import '@/components/form-components/rules';
import '@/mixins/global.mixin';
import '@/styles/index.scss';
import Vue from 'vue';
import App from './App.vue';
import './plugins/dayjs';
import i18n, { loadLanguage } from './plugins/i18n/i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './util/date-formatter';

// TODO: base on user preferences
(async () => {
  await loadLanguage('nl');

  new Vue({
    i18n,
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
})();
