import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '@/router/routes';

@Component({})
class GlobalMixin extends Vue {
  public get Routes() {
    return Routes;
  }
}

Vue.mixin(GlobalMixin);

declare module 'vue/types/vue' {
  interface Vue {
    Routes: typeof Routes;
  }
}
