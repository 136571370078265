import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { LoadableList } from '@/models/LoadableList';
import { IPerson } from '@/store/modules/user/contracts/person.contract';
import { apiService } from '@/services/api.service';
import { userModule } from '@/store/modules/user/user.module';

export interface IInternState {
  selectedInterns: IPerson[];
  interns: LoadableList<IPerson>;
}

@Module({ dynamic: true, store, name: 'intern' })
class InternModule extends VuexModule implements IInternState {
  interns = new LoadableList<IPerson>();
  selectedInterns: IPerson[] = [];

  @Action({})
  public async fetchInterns() {
    if (userModule.person) {
      const interns = await apiService.getInterns(userModule.person.id);
      this.SET_INTERNS(interns);
    }
  }

  @Action({})
  public resetInternModule() {
    this.deselectAllInterns();
    this.SET_INTERNS([]);
  }

  @Action({})
  public async selectIntern(intern: IPerson) {
    this.SELECT_INTERN(intern);
  }

  @Action({})
  public deselectAllInterns() {
    this.DESELECT_ALL_INTERNS();
  }

  @Mutation
  public SET_INTERNS(interns: IPerson[]) {
    this.interns.content = interns;
  }

  @Mutation
  public SELECT_INTERN(intern: IPerson) {
    this.selectedInterns.push(intern);
  }

  @Mutation
  public DESELECT_INTERN(intern: IPerson) {
    this.selectedInterns.filter((i) => {
      return intern.id === i.id;
    });
  }

  @Mutation
  public DESELECT_ALL_INTERNS() {
    this.selectedInterns = [];
  }
}

export const internModule = getModule(InternModule);
