import { extend, setInteractionMode  } from 'vee-validate';
import { required } from "vee-validate/dist/rules";
import i18n from "@/plugins/i18n/i18n";
import dayjs from "dayjs";

export type ValidationRuleName =
    | 'required'
    | 'is-date'
    | 'is-duration';

setInteractionMode("aggressive");

extend("required", {
  ...required,
  params: [...required.params, 'translationFieldKey'],
  message: (field: string, placeholders) => {
    if (!('translationFieldKey' in placeholders) || placeholders.translationFieldKey === undefined) {
      placeholders.translationFieldKey = field;
    }
    return i18n.t('assignmentBulkImport.bulkImport.required', { field: i18n.t('assignment.' + placeholders.translationFieldKey) }).toString();
  }
});

extend("is-date", {
  validate: (value: string) => dayjs(value, ['DD/MM/YYYY', 'DD/MM/YY', 'D/MM/YYYY', 'D/MM/YY', 'DD-MM-YYYY', 'D-MM-YYYY', 'DD-MM-YY', 'D-MM-YY']).isValid(),
  params: ['translationFieldKey'],
  message: (field: string, placeholders) => {
    if (!('translationFieldKey' in placeholders) || placeholders.translationFieldKey === undefined) {
      placeholders.translationFieldKey = field;
    }
    return i18n.t('assignmentBulkImport.bulkImport.invalidDate', { field: i18n.t('assignment.' + placeholders.translationFieldKey) }).toString();
  },
});

extend('is-duration', {
  validate: (value: string) => new RegExp(`^\\s*\\d+[:.]\\d{1,2}\\s*$`).test(value),
  params: ['translationFieldKey'],
  message: (field: string, placeholders) => {
    if (!('translationFieldKey' in placeholders) || placeholders.translationFieldKey === undefined) {
      placeholders.translationFieldKey = field;
    }
    return i18n.t('assignmentBulkImport.bulkImport.invalidDuration', { field: i18n.t('assignment.' + placeholders.translationFieldKey) }).toString();
  },
});
