import { NotificationService as ItaaNotificationService } from '@itaa/ui-components';

import Constants from '../constants';
import i18n from '../plugins/i18n/i18n';

class NotificationService extends ItaaNotificationService {
  public showGenericError(): void {
    const message = i18n.t('errors.general', { mail: Constants.supportEmailAddress }).toString();
    this.onError(message);
  }

  public showErrorByProvidedKey(key: string): void {
    const message = i18n.t(key).toString();
    this.onError(message);
  }
}

export const notificationService = new NotificationService();
