import Vue from 'vue';
import Vuex from 'vuex';
import { VuexOidcState, vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '@/config/oidc';

Vue.use(Vuex);

export interface IRootState {
  oidc: VuexOidcState;
}

export default new Vuex.Store<IRootState>({
  modules: {
    oidc: vuexOidcCreateStoreModule(oidcSettings, { namespaced: true }),
  },
});
