import Vue from 'vue';
import { UserVuetifyPreset } from 'vuetify';
import Vuetify from 'vuetify/lib/framework';
import i18n from './i18n/i18n';
import ItaaUiComponents from '@itaa/ui-components';
import { VuetifyIcons } from 'vuetify/types/services/icons';

Vue.use(Vuetify);

// Load all custom icons
const iconComponents = require.context('@/assets/svg/icons', false, /.*\.svg$/);
const iconConfig: Partial<VuetifyIcons> = {};
iconComponents.keys().forEach((key) => {
  const iconName = key.substr(2, key.length - 6);
  iconConfig[iconName] = {
    component: (() => import(`@/assets/svg/icons/${iconName}.svg`)) as any,
  };
});

const vuetifyPresets: Partial<UserVuetifyPreset> = {
  icons: {
    values: iconConfig,
  },
};

Vue.use(ItaaUiComponents, {
  vuetify: vuetifyPresets,
  i18n,
});

const vuetify = new Vuetify(vuetifyPresets);

export default vuetify;
