import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { IApiAssignment, IApiAssignmentData, IAssignmentFilterModel } from './contracts/assignment.api-contract';
import { apiService } from '@/services/api.service';
import { userModule } from '../user/user.module';
import { AppRole } from '../user/contracts/role.enum';
import { internModule } from '../intern/intern.module';
import dayjs from 'dayjs';
import { internshipModule } from '@/store/modules/internship/internship.module';
import { LoadableList } from '@/models/LoadableList';
import {
  IApiAssignmentComment,
  IApiAssignmentCommentData,
} from '@/store/modules/assignments/contracts/assignment-comment.api-contract';

export interface IAssignmentState {
  assignments: LoadableList<IApiAssignment>;
  assignment: IApiAssignment | null;
  allLoaded: boolean;
  assignmentFilterModel: IAssignmentFilterModel;
}

@Module({ dynamic: true, store, name: 'assignment' })
class AssignmentModule extends VuexModule implements IAssignmentState {
  public assignments: LoadableList<IApiAssignment> = new LoadableList<IApiAssignment>();
  public assignment: IApiAssignment | null = null;
  public allLoaded = false;
  public loading = false;
  public assignmentFilterModel: IAssignmentFilterModel = AssignmentModule.defaultAssignmentFilterModel();
  public pageSize = 10;
  public nbOfAssignments = 0;
  public totalTime: { hours: number; minutes: number } = { hours: 0, minutes: 0 };

  private static defaultAssignmentFilterModel(): IAssignmentFilterModel {
    const startDate = dayjs(internshipModule.startDate);
    return {
      disciplines: [],
      startDate: dayjs().month(startDate.month()).day(startDate.day()).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      hasComments: false,
    };
  }

  @Action({})
  public resetAssignmentModule() {
    this.clearAssignments();
    this.resetFilterModel();
    this.SET_ASSIGNMENT(null);
    this.SET_ALL_ASSIGNMENTS_LOADED(false);
  }

  @Action({})
  public async createAssignment(assignmentDetails: IApiAssignmentData): Promise<IApiAssignment> {
    return await apiService.createAssignment(assignmentDetails);
  }

  @Action({})
  public async fetchAssignments(options?: { clearAssignments: boolean }): Promise<void> {
    if (this.assignments.loaded && !options?.clearAssignments) {
      return;
    }
    const size = this.pageSize;
    const skip = 0;
    //get ids to fetch assignments of
    if (!userModule.role || !userModule.person) {
      return;
    }
    const internIds: string[] =
      userModule.role === AppRole.Mentor ? internModule.selectedInterns.map((i) => i.id) : [userModule.person.id];
    if (!internIds.length) {
      return;
    }
    const newAssignments = await this.loadNewAssignments({ skip, size, internIds });
    this.SET_ASSIGNMENTS(options?.clearAssignments ? newAssignments : [...this.assignments.content, ...newAssignments]);
    this.SET_ALL_ASSIGNMENTS_LOADED(newAssignments.length < size);
  }

  @Action({})
  public async fetchMoreAssignments(): Promise<void> {
    if (!userModule.role || !userModule.person) {
      return;
    }
    const internIds: string[] =
      userModule.role === AppRole.Mentor ? internModule.selectedInterns.map((i) => i.id) : [userModule.person.id];
    if (!internIds.length) {
      return;
    }
    const size = this.pageSize;
    const skip = this.assignments.content.length;
    const newAssignments = await this.loadNewAssignments({ skip, size, internIds });
    this.SET_ASSIGNMENTS(await this.filterDoubleAssignments([...this.assignments.content, ...newAssignments]));
    if (newAssignments.length < size) {
      this.SET_ALL_ASSIGNMENTS_LOADED(true);
    }
  }

  @Action({})
  private async filterDoubleAssignments(assignments: IApiAssignment[]): Promise<IApiAssignment[]> {
    return assignments.filter((a, index, array) => array.findIndex((as) => a.id === as.id) === index);
  }

  @Action({})
  public async loadNewAssignments(queryParams: {
    skip: number;
    size: number;
    internIds: string[];
  }): Promise<IApiAssignment[]> {
    this.SET_LOADING(true);
    try {
      const assignmentsPagedList = await apiService.getAssignments({
        ...this.assignmentFilterModel,
        internIds: queryParams.internIds,
        skip: queryParams.skip,
        take: queryParams.size,
      });
      this.SET_TOTALS({
        hours: assignmentsPagedList.totalTime.hours,
        minutes: assignmentsPagedList.totalTime.minutes,
        nbOfAssignments: assignmentsPagedList.total,
      });
      return assignmentsPagedList.result;
    } finally {
      this.SET_LOADING(false);
    }
  }

  @Action({})
  public async fetchAssignment(assignmentId: string): Promise<void> {
    let assignment = this.assignments.content.find((a) => a.id === assignmentId);
    if (!assignment) {
      assignment = await apiService.getAssignment(assignmentId);
    }
    this.SET_ASSIGNMENT(assignment);
  }

  @Action({})
  public async editAssignment({
    assignmentId,
    assignmentData,
  }: {
    assignmentId: string;
    assignmentData: IApiAssignmentData;
  }): Promise<void> {
    const assignment = await apiService.editAssignment(assignmentId, assignmentData);
    this.REPLACE_ASSIGNMENT(assignment);
  }

  @Action({})
  public clearAssignments(): void {
    this.CLEAR_ASSIGNMENTS();
  }
  @Mutation
  public CLEAR_ASSIGNMENTS(): void {
    this.assignments = new LoadableList<IApiAssignment>();
  }

  @Action({})
  public resetFilterModel(): void {
    this.UPDATE_FILTER_MODEL(AssignmentModule.defaultAssignmentFilterModel());
  }

  @Mutation
  public SET_ALL_ASSIGNMENTS_LOADED(loaded: boolean): void {
    this.allLoaded = loaded;
  }

  @Mutation
  public SET_ASSIGNMENTS(assignments: IApiAssignment[]): void {
    this.assignments.content = assignments;
  }

  @Mutation
  public SET_LOADING(loading: boolean): void {
    this.loading = loading;
  }

  @Action({})
  public clearAssignment(): void {
    this.SET_ASSIGNMENT(null);
  }

  @Mutation
  public SET_TOTALS(totals: { nbOfAssignments: number; hours: number; minutes: number }): void {
    this.nbOfAssignments = totals.nbOfAssignments;
    this.totalTime = {
      hours: totals.hours,
      minutes: totals.minutes,
    };
  }

  @Mutation
  public SET_ASSIGNMENT(assignment: IApiAssignment | null): void {
    this.assignment = assignment;
  }

  @Mutation
  public REPLACE_ASSIGNMENT(assignment: IApiAssignment): void {
    this.assignment = assignment;
    const index = this.assignments.content.findIndex((a) => a.id === assignment.id);
    if (index >= 0) {
      const assignments = [...this.assignments.content];
      assignments[index] = assignment;
      this.assignments.content = assignments;
    }
  }

  @Action({})
  public async updateFilterModel(filterModel: IAssignmentFilterModel): Promise<void> {
    this.UPDATE_FILTER_MODEL(filterModel);
  }

  @Mutation
  public UPDATE_FILTER_MODEL(filterModel: IAssignmentFilterModel): void {
    this.assignmentFilterModel = filterModel;
  }

  @Action({})
  public async addComment(commentData: IApiAssignmentCommentData): Promise<void> {
    if (commentData.comment) {
      const comment = await apiService.createComment(commentData);
      this.ADD_COMMENT(comment);
    }
  }

  @Mutation
  public ADD_COMMENT(comment: IApiAssignmentComment): void {
    const index = this.assignments.content.findIndex((a) => a.id === this.assignment?.id);
    if (this.assignments.content[index].comments) {
      this.assignments.content[index].comments?.push(comment);
    } else {
      this.assignments.content[index].comments = [comment];
    }
    this.assignment = this.assignments.content[index];
  }

  @Action({})
  public async deleteAssignment(assignmentId: string): Promise<void> {
    await apiService.deleteAssignment(assignmentId);
    await this.fetchAssignments({ clearAssignments: true });
    if (this.assignment?.id === assignmentId) {
      this.clearAssignment();
    }
  }
}

export const assignmentModule = getModule(AssignmentModule);
