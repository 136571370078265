import { roleGuard } from '@/router/guards/role.guards';
import { Routes } from '@/router/routes';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { RouteConfig } from 'vue-router';

export const employeeRoutes: RouteConfig[] = [
  {
    name: Routes.UserSelection,
    path: 'users',
    component: () => import(/* webpackChunkName: "group-employee" */ '@/views/employee/ImpersonationSelectionView.vue'),
    beforeEnter: roleGuard(AppRole.Employee, AppRole.Admin),
  },
];
