import { RouteConfig } from 'vue-router';
import { Routes } from '@/router/routes';
import Multiguard from 'vue-router-multiguard';
import { roleGuard } from '@/router/guards/role.guards';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { internSelectedGuard } from '@/router/guards/intern-selected.guard';

export const evaluationRoutes: RouteConfig[] = [
  {
    name: Routes.Evaluations,
    path: 'evaluations',
    component: () => import(/* webpackChunkName: "group-evaluation" */ '@/views/evaluations/EvaluationsOverview.vue'),
    beforeEnter: Multiguard([roleGuard(AppRole.Mentor, AppRole.Intern), internSelectedGuard]),
  },
];
