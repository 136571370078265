import { NavigationGuard } from 'vue-router';
import { userModule } from '@/store/modules/user/user.module';
import { internshipModule } from '@/store/modules/internship/internship.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { internModule } from '@/store/modules/intern/intern.module';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { Routes } from '@/router/routes';
import { importMappingModule } from '@/store/modules/import-mapping/import-mapping.module';

export const selfLoadedGuard: NavigationGuard = async (to, from, next) => {
  if (!userModule.person) {
    await userModule.fetchSelf();
  }
  next();
};

export const internSelectedGuard: NavigationGuard = async (to, from, next) => {
  if (internModule.selectedInterns.length > 0) {
    next();
  } else {
    next({ name: Routes.InternsSelection });
  }
};

export const internshipLoadedGuard: NavigationGuard = async (to, from, next) => {
  if (userModule.person && !internshipModule.startDate) {
    switch (userModule.role) {
      case AppRole.Intern: {
        await internshipModule.fetchInternship(userModule.person.id);
        break;
      }
      case AppRole.Mentor: {
        await internshipModule.fetchInternship(internModule.selectedInterns[0].id);
        break;
      }
      default: {
        break;
      }
    }
    assignmentModule.resetFilterModel();
  }
  next();
};

export const rolesLoadedGuard: NavigationGuard = async (to, from, next) => {
  if (!userModule.roles.loaded && userModule.person) {
    await userModule.fetchRoles(userModule.person.id);
  }
  next();
};

export const importMappingGuard: NavigationGuard = async (to, from, next) => {
  if (!importMappingModule.importMapping && userModule.person) {
    await importMappingModule.fetchImportMapping(userModule.person.id);
  }
  if (!importMappingModule.importMapping) {
    next({ name: Routes.CreateImportMapping });
  } else {
    next();
  }
};
