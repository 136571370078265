
























































































import RolesDialog from "@/components/roles/RolesDialog.vue";
import { IPagedResult } from "@/contracts/paging.contract";
import { IApiUserRole } from '@/contracts/user-role.contract';
import { apiService } from "@/services/api.service";
import { IPerson } from "@/store/modules/user/contracts/person.contract";
import { getRoleColor } from '@/views/admin/role-colors';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from "vuetify";


@Component({
  methods: {getRoleColor},
  components: { RolesDialog }
})
export default class PersonRolesTable extends Vue {
  @Prop()
  public items!: IPagedResult<IPerson>;

  @Prop()
  public readonly page!: number;

  @Prop()
  public readonly pageSize!: number;

  @Prop()
  public readonly loadingData!: boolean;

  public showDialog = false;
  public selectedPerson: IPerson | null = null;
  public roles: IApiUserRole[] = [];
  public loadingRoles = false;

  public get headers(): DataTableHeader[] {
    return [
      { text: 'itaaNr', value: 'itaaNr' },
      { text: this.$t('roleManager.table.fullName').toString(), value: 'fullName' },
      { text: this.$t('roleManager.table.email').toString(), value: 'email' },
      { text: this.$t('roleManager.table.roles').toString(), value: 'roles'},
      { text: this.$t('roleManager.table.internSince').toString(), value: 'internSince'},
      { text: this.$t('roleManager.table.actions').toString(), value: 'actions', sortable: false },
    ]
  }

  public get totalItems() {
    return this.items.total ?? 100000;
  }

  public onPaginationChanged(page: number, pageSize: number): void {
    this.$emit('page-change', {
      page,
      pageSize
    });
  }

  public editRoles(item: IPerson): void {
    this.loadingRoles = true;
    apiService.getRolesByUsersId(item.id).then((roles) => {
      this.roles = roles;
      this.selectedPerson = item;
      this.loadingRoles = false;
      this.showDialog = true;
    });
  }

  public onCloseDialog(roles?: IApiUserRole[]) {
    this.selectedPerson = null;
    this.showDialog = false;
    if(roles) {
      this.$emit('roles-updated', roles);
    }
  }
}
