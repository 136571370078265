import { AppRole } from "@/store/modules/user/contracts/role.enum";

export function getRoleColor(role: string): string {
    switch (role) {
        case AppRole.Intern:
            return 'primary';
        case AppRole.Admin:
            return 'error';
        case AppRole.Employee:
            return 'secondary';
        case AppRole.Mentor:
            return 'warning';
        default:
            return 'grey';
    }
}