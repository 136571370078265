









import { Component, Vue } from 'vue-property-decorator';
import Constants from '@/constants';

@Component({})
export default class PvfcButton extends Vue {
  public get pvfcUrl(): string {
    return Constants.pvfcUrl;
  }
}
