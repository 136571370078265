







import { Component, Vue } from 'vue-property-decorator';
import { IPerson } from '@/store/modules/user/contracts/person.contract';
import { internModule } from '@/store/modules/intern/intern.module';
import { Routes } from '@/router/routes';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import UserSelector from '@/components/nav-side-bar/UserSelector.vue';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';

@Component({
  components: { UserSelector },
})
export default class InternSelector extends Vue {

  public selectedInterns: number[] = [1];

  public goToInternsSelection(): void {
    internModule.deselectAllInterns();
    assignmentModule.clearAssignments();
    assignmentModule.clearAssignment();
    evaluationModule.resetEvaluationModule();
    this.$router.push({ name: Routes.InternsSelection });
  }

  //TODO: currently only one intern is shown+is selectable in list, but most of the functionality for refinement is made. In case refinement to select multiple interns in list is implemented, change this to interns and change the selection implementation.
  public get interns(): IPerson[] {
    return internModule.selectedInterns;
  }
}
