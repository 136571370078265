




































































































































import { IApiUserRole } from "@/contracts/user-role.contract";
import { apiService } from "@/services/api.service";
import { notificationService } from "@/services/notification.service";
import { IPerson } from "@/store/modules/user/contracts/person.contract";
import { AppRole } from "@/store/modules/user/contracts/role.enum";
import { getRoleColor } from "@/views/admin/role-colors";
import { Component, Model, Prop, VModel, Vue, Watch } from "vue-property-decorator";

@Component({
  methods: {
    Role() {
      return AppRole
    }, getRoleColor},
  components: {
  },
})
export default class RolesDialog extends Vue {
  @Model('input')
  public readonly isOpen!: boolean;

  @Prop()
  person!: IPerson | null;

  @Prop()
  roles!: IApiUserRole[];

  @VModel()
  public selectedRole: string | null = null;

  public loadingAddRole = false;

  async disableRole(role: IApiUserRole) {
    await apiService.deleteRole(role.id)
    role.deleted = new Date();
    if(this.person) this.person.updated = new Date();
  }

  get roleSelections() {
    //get all possible rows and remove the ones that are already in roles
    return (Object.keys(AppRole) as Array<keyof typeof AppRole>).map((role) => {
      return role;
    })
    .filter((role) => {
      return !this.roles.some((r) => r.appRole == role && r.deleted == null);
    });
  }

  addRole() {
    if(this.person && this.selectedRole) {
      this.loadingAddRole = true;
      apiService.addRoleToUser(this.person.id, this.selectedRole).then((result) => {
        if(result) {
          this.roles.push(result);
          this.selectedRole = null;
          this.loadingAddRole = false;
          if(this.person) this.person.updated = new Date();
        } else {
          notificationService.onError(this.$t('roleManager.addContact.noValidInternships').toString());
          this.loadingAddRole = false;
        }
      }).catch((x) => {
        if(x.response.status == 400) {
          if(this.selectedRole == AppRole.Mentor) {
            notificationService.onError(this.$t('roleManager.addContact.noValidMentor').toString());
          } else if(this.selectedRole == AppRole.Intern) {
            notificationService.onError(this.$t('roleManager.addContact.noValidInternships').toString());
          } else {
            notificationService.onError(this.$t('roleManager.addContact.wentWrong').toString());
          }
        }
        this.loadingAddRole = false;
      });
    }
  }

  get visibleRoles() {
    return this.roles.filter((role) => {
      return role.deleted == null;
    });
  }

  reset() {
    this.selectedRole = null;
    this.loadingAddRole = false;
    this.person = null;
    this.roles = [];
  }

  @Watch('isOpen')
  onIsOpenChanged() {
    if (!this.isOpen) {
      this.reset();
    }
  }

}
