import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { NavigationGuard } from 'vue-router';
import { userModule } from '@/store/modules/user/user.module';
import { Routes } from '@/router/routes';

export const roleGuard: (role: AppRole, ...additionalRoles: AppRole[]) => NavigationGuard =
  (...allowedRoles: AppRole[]) =>
  (to, from, next) => {
    //currently no user for multiple roles. Implemented for possible future requirement.
    if (!userModule.role) {
      next({
        name: Routes.RoleSelection,
        query: {
          returnUrl: to.fullPath,
        },
      });
    }
    if (userModule.role && allowedRoles.includes(userModule.role)) {
      return next();
    }

    next({
      name: Routes.Unauthorized,
    });
  };
