







import { Component, Vue } from 'vue-property-decorator';
import { userModule } from '@/store/modules/user/user.module';
import { Routes } from '@/router/routes';
import { IPerson } from '@/store/modules/user/contracts/person.contract';
import UserSelector from '@/components/nav-side-bar/UserSelector.vue';

@Component({components: {UserSelector}})
export default class ImpersonationSelector extends Vue {
  public goToUserSelection(): void {
    userModule.stopImpersonation();
    this.$router.push({ name: Routes.UserSelection });
  }

  public get user(): IPerson[] {
    return userModule.person ? [userModule.person] : [];
  }
}
