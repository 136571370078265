import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { authGuard } from './guards/auth.guard';
import NavigationRoot from '@/views/NavigationRoot.vue';
import { Routes } from '@/router/routes';
import multiguard from 'vue-router-multiguard';
import { navigationRoutes } from '@/router/navigationRoutes';
import { rolesLoadedGuard, selfLoadedGuard } from '@/router/guards/data.guards';
import { masterdataGuard } from './guards/masterdata.guard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: NavigationRoot,
    beforeEnter: multiguard([authGuard, selfLoadedGuard, rolesLoadedGuard, masterdataGuard]),
    children: navigationRoutes,
  },
  {
    path: '/unauthorized',
    name: Routes.Unauthorized,
    component: () => import('@/views/Unauthorized.vue'),
  },
  {
    path: '/callback',
    component: () => import('@/views/auth/OidcCallback.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
