import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"flat":"","nav":""}},[_c(VDivider,{staticClass:"ma-2",attrs:{"light":""}}),_c(VSubheader,{staticClass:"my-n3"},[_vm._v(" "+_vm._s(_vm.subHeader)+": ")]),_c(VListItemGroup,{attrs:{"multiple":"","mandatory":""},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},_vm._l((_vm.users),function(user,index){return _c(VListItem,{key:index},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v(" $show ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"itaa-navigation"},[_vm._v(" "+_vm._s(((user.firstName) + " " + (user.lastName.charAt(0)) + "."))+" ")])])],1)],1)}),1),_c('div',{staticClass:"px-2"},[_c(VBtn,{staticClass:"mt-2 wrapping-button",attrs:{"block":"","color":"white","outlined":""},on:{"click":_vm.goToSelectionView}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }