import { NavigationGuard } from 'vue-router';
import { internModule } from '@/store/modules/intern/intern.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { userModule } from '@/store/modules/user/user.module';
import { Routes } from '../routes';

export const internSelectedGuard: NavigationGuard = async (to, from, next) => {
  //Guard only triggers when AppRole = mentor
  if (userModule.role === AppRole.Mentor && !internModule.selectedInterns.length) {
    next({
      name: Routes.InternsSelection,
    });
  }
  next();
};
