import { roleGuard } from '@/router/guards/role.guards';
import { Routes } from '@/router/routes';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { RouteConfig } from 'vue-router';

export const reportRoutes: RouteConfig[] = [
  {
    name: Routes.CreateDisciplinesReport,
    path: 'disciplines',
    component: () => import(/* webpackChunkName: "group-report" */ '@/views/report/CreateDisciplinesReportView.vue'),
    beforeEnter: roleGuard(AppRole.Employee, AppRole.Admin),
  },
  {
    name: Routes.CreateEvaluationsReport,
    path: 'evaluations',
    component: () => import(/* webpackChunkName: "group-report" */ '@/views/report/CreateEvaluationsReportView.vue'),
    beforeEnter: roleGuard(AppRole.Employee, AppRole.Admin),
  },
  {
    name: Routes.CreateInternReport,
    path: 'interns',
    component: () => import(/* webpackChunkName: "group-report" */ '@/views/report/CreateInternReportView.vue'),
    beforeEnter: roleGuard(AppRole.Employee, AppRole.Admin),
  },
];
