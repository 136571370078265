import { VuexOidcClientSettings } from 'vuex-oidc';
import Constants from '../constants';

const redirectUri = `${window.location.protocol}//${window.location.host}/callback`;

export const oidcSettings: VuexOidcClientSettings = {
  authority: Constants.authConfig.authority,
  clientId: Constants.authConfig.clientId,
  redirectUri,
  silentRedirectUri: redirectUri,
  automaticSilentRenew: true,
  responseType: 'code',
  scope: 'openid profile',
  extraQueryParams: {
    audience: Constants.authConfig.audience,
  },
  loadUserInfo: false,
  postLogoutRedirectUri: window.location.origin,
};
