





























import { cleanObject } from "@/util/util";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';


type QueryParameters<TFilterModel> = Partial<{ page: number; pageSize: number } & TFilterModel & { [index: string]: any }>;

@Component({})
export default class FilteredItemsPage<TFilterModel extends Dictionary<string | (string | null)[]>, TItems> extends Vue {
  @Prop()
  public readonly title!: string;

  @Prop()
  public readonly baseFilterModel!: (empty?: boolean) => TFilterModel;

  @Prop()
  public readonly queryParser!: (query: Dictionary<string | (string | null)[]>) => Partial<TFilterModel>;

  @Prop({ default: 'page' })
  public readonly pageQueryProperty!: string;

  @Prop({ default: 'pageSize' })
  public readonly pageSizeQueryProperty!: string;

  @Prop({ default: true })
  public readonly showTitle!: boolean;

  @Prop()
  public readonly tablePrefix!: string;

  @Prop()
  public readonly currentTab!: string;

  public filterModel: Dictionary<string | (string | null)[]> = this.baseFilterModel ? this.baseFilterModel() : {};
  public page = 0;
  public pageSize = 20;
  public tableInitialized = false;

  public async created() {
    this.tableInitialized = false;
    this.filterModel = this.baseFilterModel();
    if (
      Object.keys(this.$route.query).length === 0 &&
      Object.values(this.filterModel).some((value) => value !== null && value !== undefined && value !== '')
    ) {
      await this.initializeDefaultQueryParameters(this.filterModel);
    }
    try {
      Object.assign(this.filterModel, this.$route.query);
      const extra = (this.queryParser && this.queryParser(this.$route.query)) || {};
      this.filterModel = { ...this.filterModel, ...extra };
      this.page = parseInt((this.$route.query[this.pageQueryProperty] as string) || '1');
      this.pageSize = parseInt((this.$route.query[this.pageSizeQueryProperty] as string) || '20');

      if(this.tablePrefix) {
        this.loadFilterFromLocalStorage();
        this.onFilter();
      } else {
        this.loadItemsFromQuery();
      }

    } finally {
      this.tableInitialized = true;
    }
  }

  private async initializeDefaultQueryParameters(filter: any) {
    await this.$router.push({ query: cleanObject(filter) as any });
  }

  public loadNewPage(pageInfo: { page: number; pageSize: number }): void {
    this.pageSize = pageInfo.pageSize;
    const currentQuery = {
      ...this.$route.query,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
    this.setUrlQueryParametersAndLoad(currentQuery);
  }

  private async setUrlQueryParametersAndLoad(parameters: Partial<{ page: number; pageSize: number } & { [index: string]: any }>) {
    // parse/stringify is added because Vue sometimes doesn't recognize changes to the arrays (submitter and status).
    // Probably has to do something with observers https://github.com/vuejs/vue-router/issues/1182#issuecomment-405977211
    if ('page' in parameters && this.pageQueryProperty != 'page') {
      parameters[this.pageQueryProperty] = parameters['page'];
      delete parameters['page'];
    }
    if ('pageSize' in parameters && this.pageSizeQueryProperty != 'pageSize') {
      parameters[this.pageSizeQueryProperty] = parameters['pageSize'];
      delete parameters['pageSize'];
    }
    const query = JSON.parse(JSON.stringify(cleanObject(parameters)));
    this.pageSize = query.pageSize || this.pageSize;
    this.page = query.page || this.page;
    if (this.$route.fullPath !== this.$router.resolve({ query }).route.fullPath) {
      await this.$router.push({ query });
    }
    if(this.tablePrefix) this.saveFilterInLocalStorage()
    this.loadItemsFromQuery();
  }

  private saveFilterInLocalStorage() {
    const filter = JSON.stringify(this.getFilter());
    localStorage.setItem(`${this.tablePrefix}-filter`, filter);
  }

  private loadFilterFromLocalStorage() {
    const filter = localStorage.getItem(`${this.tablePrefix}-filter`)
    if (filter) {
      this.filterModel = JSON.parse(filter);
      const filterModel = this.filterModel as any;
      this.pageSize = filterModel.pageSize;
      this.page = filterModel.page;
    }
  }

  private clearFilterInLocalStorage() {
    localStorage.removeItem(`${this.tablePrefix}-filter`);
  }

  @Watch('$route.query.tab')
  onTabChanged() {
    if(this.currentTab && this.currentTab != this.$route.query.tab) return;
    if (this.tablePrefix) {
      const filter = localStorage.getItem(`${this.tablePrefix}-filter`)
      if(filter) {
        const parsedFilter = JSON.parse(filter);
        if (parsedFilter.tab == this.currentTab) {
          this.loadFilterFromLocalStorage();
          this.onFilter();
        }
      }
    }
  }

  private getFilter() {
    return this.currentTab ? { ...this.filterModel, page: this.page, pageSize: this.pageSize, tab: this.currentTab } : { ...this.filterModel, page: this.page, pageSize: this.pageSize };
  }

  public onFilter(): void {
    const filter = this.getFilter();

    // Check if the new filter is the same as the current route query
    if (JSON.stringify(filter) === JSON.stringify(this.$route.query)) {
      return;
    }

    if(this.tablePrefix) this.saveFilterInLocalStorage()

    if(this.currentTab) {
      if(this.currentTab == this.$route.query.tab) {
        this.setUrlQueryParametersAndLoad(filter);
      }
    } else {
      this.setUrlQueryParametersAndLoad(filter);
    }
  }

  public clearFilter(): void {
    this.filterModel = this.baseFilterModel(true);
    this.page = 1;
    //get current tab from query (to prevent losing it)
    const tab = this.$route.query.tab;
    // When filtering keep searchString
    this.clearFilterInLocalStorage();
    this.setUrlQueryParametersAndLoad({
      page: this.page,
      pageSize: this.pageSize,
      ...this.filterModel,
      tab
    });
  }

  private loadItemsFromQuery(): void {
    const queryParameters = {
      ...this.baseFilterModel(true),
      ...this.$route.query,
    } as unknown as QueryParameters<TFilterModel>;
    if (this.pageQueryProperty in queryParameters && this.pageQueryProperty != 'page') {
      queryParameters['page'] = queryParameters[this.pageQueryProperty];
      delete queryParameters[this.pageQueryProperty];
    }
    if (this.pageSizeQueryProperty in queryParameters && this.pageSizeQueryProperty != 'pageSize') {
      queryParameters['pageSize'] = queryParameters[this.pageSizeQueryProperty];
      delete queryParameters[this.pageSizeQueryProperty];
    }
    this.$emit('load-items', queryParameters);
  }

  public updateFilterModel(event: { target: { value: TFilterModel } }): void {
    this.filterModel = event.target.value;
  }
}
