import Vue from 'vue';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import i18n from '@/plugins/i18n/i18n';

dayjs.extend(localizedFormat);
dayjs.locale(i18n.locale);

export function formatDate(value: string | Date | null | undefined, format = 'DD MMM YYYY') {
  if (!value) {
    return '';
  }
  return dayjs(value).format(format);
}

export function formatDateDefault(value: string) {
  return formatDate(value, 'DD MMM YYYY');
}

export function formatDuration(durationInHours: number): string {
  const hours = Math.floor(durationInHours);
  const minutes = Math.round((durationInHours - hours) * 60);
  //TODO: waarom afronding van 10 min naar 0? hoe raar is da...
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

export function formatTimeAsDuration(time: string) {
  const timeSplit = time.split(':');
  return `${(parseInt(timeSplit[0], 10) * 1 + parseInt(timeSplit[1], 10) / 60).toFixed(2)}`;
}

export function formatTime(value: string | Date | null | undefined, format = 'HH:mm') {
  if (!value) {
    return '';
  }

  return dayjs(value, 'HH:mm:ss').format(format);
}

Vue.filter('formatDate', formatDate);
Vue.filter('formatDuration', formatDuration);
Vue.filter('formatTime', formatTime);
