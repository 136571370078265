import { roleGuard } from "@/router/guards/role.guards";
import { Routes } from "@/router/routes";
import { AppRole } from "@/store/modules/user/contracts/role.enum";
import RolesManager from "@/views/admin/RoleManager.vue";
import { RouteConfig } from "vue-router";

export const adminRoutes: RouteConfig[] = [
  {
    name: Routes.RolesManager,
    path: 'roles-manager',
    component: RolesManager,
    beforeEnter: roleGuard(AppRole.Admin),
  },
];
